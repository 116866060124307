export const countryMasterCodes: Dictionary<Dictionary<number>> = {
  MY: {
    'MALAYAN BANKING BERHAD (MayBank)': 10329,
    AMBANK: 10334,
    'BANK OF CHINA': 10343,
    'Affin Bank Berhad': 11120,
    'Affin Islamic Bank Berhad': 11121,
    'Agro Bank': 11122,
    'Alliance Bank Berhad': 11123,
    'Alliance Islamic Bank Berhad': 11124,
    'Al-Rajhi BIC (M)': 11125,
    'AmIslamic Bank': 11126,
    'Bank Islam Malaysia Berhad': 11128,
    'Bank Kerjasama Rakyat Berhad': 11129,
    'Bank Muamalat Malaysia Berhad': 11130,
    'Bank of America Malaysia Berhad': 11131,
    'Bank of Tokyo-Mitsubishi UFJ (M) Berhad': 11132,
    'Bank Simpanan Nasional Berhad': 11133,
    'BNP Paribas (M) Bhd': 11134,
    'BNP Paribas (M) Bhd (Islamic)': 11135,
    'CIMB Bank Berhad': 11136,
    'CIMB Islamic Bank': 11137,
    Citibank: 11138,
    'Deutsche Bank (M) Bhd': 11139,
    'Hong Leong Bank Bhd': 11140,
    'Hong Leong Islamic Bank Bhd': 11141,
    'HSBC Amanah Malaysia': 11142,
    'HSBC Bank Berhad': 11143,
    'Indust & Comm Bank of China (M) Berhad': 11144,
    'JP Morgan Chase Bank Berhad': 11145,
    'Kuwait Finance House Malaysia Berhad': 11146,
    'MayBank Islamic Berhad': 11148,
    'Mizuho Bank (M) Berhad': 11149,
    'OCBC AL-AMIN Bank Berhad': 11150,
    'OCBC Bank Berhad': 11151,
    'Public Bank Bhd': 11152,
    'Public Finance Bhd': 11153,
    'Public Islamic Bank Bhd': 11154,
    'RHB Bank Berhad': 11155,
    'RHB Islamic Bank Berhad': 11156,
    'Standard Chartered Bank': 11157,
    'Standard Chartered SAADIQ Bhd': 11158,
    'Sumitomo Mitsui Banking Corp (M) Bhd': 11159,
    'The Royal Bank of Scotland Bhd': 11160,
    'United Overseas Bank (M) Bhd': 11161
  },
  CA: {
    'All Trans Financial Services Credit Union Limited': 11306,
    'La Confédération des Caisses Populaires et D’Économie Desjardins du Québec': 11307,
    'La Fédération des caisses populaires du Manitoba Inc.': 11308,
    'Central 1 Credit Union Ontario': 11309,
    'La Fédération des Caisses Populaires de l’Ontario Inc.': 11310,
    'Airline Financial Credit Union Limited': 11311,
    'Meridian Credit Union': 11312,
    'Atlantic Central': 11313,
    'Dundalk District Credit Union Limited': 11314,
    'Alterna Savings and Credit Union': 11315,
    'Goderich Community Credit Union Limited': 11316,
    'Ontario Civil Service Credit Union Limited': 11317,
    'Concentra Financial Services Association': 11318,
    'Golden Horseshoe Credit Union Limited': 11319,
    'La Fédération des Caisses Populaires Acadiennes Limitée   ': 11320,
    'Credit Union Central of Manitoba Limited': 11321,
    'Credit Union Central of Saskatchewan': 11322,
    "Alliance des caisses populaires de l'Ontario Limitée": 11323,
    'Credit Union Central Alberta Limited': 11324,
    'Bank of Montreal': 11210,
    'Scotiabank (The Bank of Nova Scotia)': 11211,
    'Royal Bank of Canada': 11212,
    'The Toronto-Dominion Bank': 11213,
    'National Bank of Canada': 11214,
    'Canadian Imperial Bank of Commerce': 11215,
    'HSBC Bank Canada': 11216,
    'Canadian Western Bank': 11217,
    'Laurentian Bank of Canada': 11218,
    'Bank of Canada': 11219,
    'Alberta Treasury Branches': 11220,
    'Royal Bank of Scotland N.V. (Canada Branch)': 11221,
    'Bank of America, National Association': 11222,
    'The Bank of New York Mellon': 11223,
    'Bank of Tokyo-Mitsubishi UFJ (Canada)': 11224,
    'BNP Paribas (Canada)': 11225,
    'Citibank Canada': 11226,
    'Deutsche Bank AG': 11227,
    'Mega International Commercial Bank (Canada)': 11228,
    'JPMorgan Chase Bank National Association': 11229,
    'Korea Exchange Bank of Canada': 11230,
    'Mizuho Corporate Bank Ltd. Canada Branch': 11231,
    'UBS Bank (Canada)': 11232,
    'Société Générale (Canada Branch)': 11233,
    'State Bank of India (Canada) Alberta': 11234,
    'Sumitomo Mitsui Banking Corporation of Canada': 11235,
    'Amex Bank of Canada': 11236,
    'Industrial and Commercial Bank of China (Canada)': 11237,
    'Bank of China (Canada)': 11238,
    'Citizens Bank of Canada': 11239,
    'First Nations Bank of Canada': 11240,
    'BofA Canada Bank': 11241,
    'J.P. Morgan Bank Canada': 11242,
    'CTC Bank of Canada': 11243,
    'U.S. Bank National Association': 11244,
    'Habib Canadian Bank': 11245,
    'Rabobank Nederland': 11246,
    'Capital One Bank (Canada Branch)': 11247,
    'President‚Äôs Choice Financial': 11248,
    'State Street': 11249,
    'Citibank N.A.': 11250,
    'Comerica Bank': 11251,
    'First Commercial Bank': 11252,
    'HSBC Bank USA National Association': 11253,
    'Pacific & Western Bank of Canada': 11254,
    'United Overseas Bank Limited': 11255,
    'Maple Bank': 11256,
    'Canadian Tire Bank': 11257,
    'UBS AG Canada Branch': 11258,
    'ICICI Bank Canada': 11259,
    'Bank West': 11260,
    'Dundee Bank of Canada': 11261,
    'General Bank of Canada': 11262,
    'Fifth Third Bank': 11263,
    'Société Générale (Canada Branch) Ontario': 11264,
    'Bridgewater Bank': 11265,
    'The Northern Trust Company Canada Branch': 11266,
    'DirectCash Bank': 11267,
    'Jameson Bank': 11268,
    'Shinhan Bank Canada': 11269,
    'M&T Bank': 11270,
    'HomEquity Bank': 11271,
    'Walmart Canada Bank': 11272,
    "Barclay's Bank PLC Canada Branch": 11273,
    'MonCana Bank of Canada': 11274,
    'Community Trust Company': 11275,
    'The Canada Trust Company': 11276,
    'Laurentian Trust of Canada Inc.': 11277,
    'Effort Trust Company': 11278,
    'Investors Group Trust Co. Ltd.': 11279,
    'Manulife Bank of Canada': 11280,
    'CIBC Trust Corporation': 11281,
    'Montreal Trust Company of Canada': 11282,
    'Sun Life Financial Trust Inc.': 11283,
    'Peace Hills Trust Company': 11284,
    'Royal Trust Company': 11285,
    'Royal Trust Corporation of Canada': 11286,
    'National Trust Company': 11287,
    'Royal Bank Mortgage Corporation': 11288,
    'TD Mortgage Corporation': 11289,
    'TD Pacific Mortgage Corporation': 11290,
    'HSBC Mortgage Corporation (Canada)': 11291,
    'Scotia Mortgage Corporation': 11292,
    'CS Alterna Bank': 11293,
    'ING Bank of Canada': 11294,
    'B2B Bank (formerly B2B Trust)': 11295,
    'ResMor Trust Company': 11296,
    'Peoples Trust Company': 11297,
    'The Equitable Trust Company': 11298,
    'Industrial Alliance Trust Inc.': 11299,
    'Manulife Trust Company': 11300,
    'Household Trust Company': 11301,
    'Latvian Credit Union Limited': 11302,
    'Communication Technologies Credit Union Limited': 11303,
    'Arnstein Community Credit Union Limited': 11304,
    'Central 1 Credit Union British Columbia': 11305
  },
  CN: {
    'INDUSTRIAL AND COMMERCIAL BANK CHINA': 10101,
    'BANK OF CHINA': 10102,
    'CHINA CONSTRUCTION BANK': 10103,
    'AGRICULTURAL BANK OF CHINA': 10104,
    'POSTAL SAVINGS BANK OF CHINA': 10105,
    'BANK OF NANJING': 10106,
    'BAOSHANG BANK': 10107,
    'CHINA EVERBRIGHT BANK': 10108,
    'CHINA MINSHENG BANK': 10109,
    'INDUSTRIAL BANK CO LTD': 10110,
    'BEIJING RURAL COMMERCIAL BANK': 10111,
    'HANA BANK CHINA': 10112,
    'CHINA BOHAI BANK': 10113,
    'BANK OF TIANJIN': 10114,
    'TIANJIN RURAL COMMERCIAL BANK': 10115,
    'KOREA EXCHANGE BANK': 10116,
    'SHENZHEN RURAL COMMERCIAL BANK': 10117,
    'PING AN BANK': 10118,
    'DONGGUAN RURAL COMMERCIAL BANK': 10119,
    'BANK GUANGZHOU': 10120,
    'GUANGZHOU RURAL COMMERCIAL BANK': 10121,
    'BANK OF COMMUNICATIONS': 10122,
    'CHINA CITIC BANK': 10123,
    'HUA XIA BANK': 10124,
    'CHINA MERCHANTS BANK': 10125,
    'CHINA GUANGFA BANK': 10126,
    'BANK OF BEIJING': 10127,
    'SHINHAN BANK (CHINA)': 10128,
    'BANK OF DONGGUAN': 10129,
    'SHUNDE RURAL COMMERCIAL BANK': 10130,
    'SHANGHAI PUDONG DEVELOPMENT BANK': 10131,
    'ANHUI RURAL CREDIT UNION': 10132,
    'XIAMEN BANK': 10133,
    'GUANGXI BEIBU GULF BANK': 10134,
    'BANK OF CHANGSHA': 10135,
    'KUNSHAN RURAL COMMERCIAL BANK': 10136,
    'BANK OF SUZHOU': 10137,
    'RURAL COMMERCIAL BANK OF ZHANGJIAGANG': 10138,
    'BANK OF NANCHANG': 10139,
    'BANK OF SHANGRAO': 10140,
    'DONGYING CITY COMMERCIAL BANK': 10141,
    'BANK OF JINING': 10142,
    'LAISHANG BANK': 10143,
    'LINSHANG BANK': 10144,
    'QISHANG BANK': 10145,
    'BANK OF QINGDAO': 10146,
    'BANK OF RIZHAO': 10147,
    'TAIAN CITY COMMERCIAL BANK': 10148,
    'The Bank of East Asia (China) Ltd': 12987,
    'Ningbo Commerce Bank Corporation Ltd': 12988,
    'Clearing House Financial Markets Co. Ltd': 12989,
    'Citibank (China) Co Ltd': 12990,
    'Jiangsu Changshu Rural Commercial Bank Co Ltd': 12991,
    'The Export-Import Bank of China': 12992,
    'Fubon Bank (China) Co Ltd': 12993,
    'Bank of Gansu Co Ltd': 12994,
    'Habib Bank Limited': 12995,
    'Jilin Hunchun Rural Commercial Bank': 12996,
    'Evergrowing Bank Co Ltd': 12997,
    'Hefei Science and Technology Rural Commercial Bank Co Ltd': 12998,
    'Bank of Jinhua Co Ltd': 12999,
    'Bank of Jilin Co Ltd': 13000,
    'Bank of Liaoyang Co Ltd': 13001,
    'Metropolitan Bank(China) Ltd': 13002,
    'Jiangsu Zhangjiagang Rural Commercial Bank Co Ltd': 13003,
    'Jiangsu Jiangnan Rural Commercial Bank Co., Ltd': 13004,
    'Jinan Rural Commercial Bank Co., Ltd': 13005,
    'China Development Bank': 13006,
    'Yanbian Rural Commercial Bank': 13007,
    'Ningbo Yinzhou Rural Commercial Bank Co Ltd': 13008,
    'Zhejiang E-Commerce Bank Co Ltd': 13009,
    'Zhejiang Mintai Commercial Bank Co Ltd': 13010,
    Enshi: 10704,
    'WEIHAI CITY COMMERCIAL BANK': 10149,
    'YANTAI BANK': 10150,
    'BANK OF DALIAN': 10151,
    'BANK OF JINZHOU': 10152,
    'ANSHAN CITY COMMERCIAL BANK': 10153,
    'BANK OF HULUDAO': 10154,
    'BANK OF SHANGHAI': 10155,
    Ezhou: 10705,
    'ZHEJIANG CHOUZHOU COMMERCIAL BANK': 10156,
    'BANK OF HANGZHOU': 10157,
    'BANK OF HUZHOU': 10158,
    'BANK OF NINGBO': 10159,
    'ZHEJIANG TAILONG COMMERCIAL BANK': 10160,
    'BANK OF WENZHOU': 10161,
    'YINZHOU BANK': 10162,
    'CHINA ZHESHANG BANK': 10163,
    'YELLOW RIVER RURAL COMMERCIAL BANK': 10164,
    'SHANGHAI RURAL COMMERCIAL BANK': 10165,
    'Bank Of Jiangsu': 10166,
    'Guangdong Nanyue Bank': 10167,
    jingsha: 10730,
    'China Resources Bank Of Zhuhai': 10168,
    'Shenzhen Development Bank': 10169,
    'Guangdong Development Bank': 10170,
    'Rural Credit Cooperative Of Shandong': 10171,
    'Jilin Province Rural Credit Union': 10172,
    'Hubei Rural Credit Cooperatives': 10173,
    'Hainan Province Rural Credit Cooperatives ': 10174,
    'Yunnan Rural Credit Cooperatives': 10175,
    'Sichuan Rural Credit Union': 10176,
    'Jiangxi Rural Credit Union': 10177,
    'Guangdong Rural Credit Cooperatives Union': 10178,
    'Guizhou Rural Credit Union': 10179,
    'Fujian Rural Credit Union': 10180,
    'Zhejiang Rural Credit Union': 10181,
    'Nanyang Commercial Bank': 10590,
    'United Overseas Bank': 10591,
    'Standard Chartered Bank': 10592,
    'Development Bank of Singapore Co.,Ltd': 10593,
    'Bank Of Baoding': 10594,
    'Bank Of Tibet': 10595,
    'Shengjing Bank': 10596,
    'Bank Of Fushun': 10597,
    'Inner Mongolia Rural Credit Cooperative': 10598,
    'Agricultural Development Bank': 10689,
    'Anji Rural Credit Cooperative Association': 10691,
    'Bank of Chengdu': 10692,
    'Bank of Mongolia': 10693,
    'Bank of Taizhou': 10694,
    'Bank of Yingko': 10695,
    'Beibu gulf bank': 10696,
    'Zhongyuan Bank': 10697,
    'ChangAn Bank': 10698,
    'Bank of Chongqing': 10699,
    'Chouzhou Bank': 10700,
    'Commercial Bank': 10701,
    'Credit Cooperatives': 10702,
    'Dongying Bank': 10703,
    'Harbin Bank': 10715,
    'Bank of Hebei': 10716,
    'Hengshui Bank': 10718,
    'Hong kong Shanghai Bank': 10719,
    Huangshi: 10720,
    Huangzhou: 10721,
    'Huarong xiangjiang Bank': 10722,
    'Hubei Bank': 10723,
    'Xingye Bank': 10724,
    Janghan: 10725,
    'Jiangnan guomin country Bank': 10726,
    'jiangsu changjiang commercial bank': 10727,
    Jinben: 10728,
    'JC Bank': 10729,
    'Kunlun Bank': 10731,
    'Langfang Bank': 10732,
    'Bank of Liuzhou': 10733,
    'Longjiang Bank': 10734,
    'Huishang Bank': 10735,
    'Jinshang Bank': 10736,
    'HengFeng Bank': 10737,
    'qilu bank': 10738,
    'Qing bank': 10739,
    'bank of quanzhou': 10740,
    'fudian bank': 10741,
    'Rural Commercial Bank': 10743,
    'Rural cooperative bank': 10744,
    'Rural credit cooperative': 10746,
    'spd bank': 10747,
    'shanghai  rural corperative bank': 10748,
    'Bank of shaoxing': 10749,
    shiyan: 10750,
    'shizuishan bank': 10751,
    'haixia bank': 10752,
    suizhao: 10753,
    'tangshan bank': 10754,
    'dezhou bank': 10755,
    'three corges bank': 10756,
    'donghai bank': 10757,
    'rural bank': 10758,
    'weifang yinhang': 10759,
    wuhan: 10760,
    'bank of xian': 10761,
    xiangcheng: 10762,
    xianning: 10763,
    xiaogan: 10764,
    'xiaxiang bank': 10765,
    yichang: 10766,
    'zhengzhou bank': 10767,
    'zhumadian bank': 10768,
    'Ganzhou bank': 10706,
    'Yinzuo Rural Bank': 10707,
    'Guangzhou Unionpay': 10708,
    'Guilin Bank': 10709,
    'Bank of Guiyang': 10710,
    'Bank of Guizhou': 10711,
    'Hana Bank': 10712,
    'Bank of Handan': 10713,
    'Hankou Bank': 10714,
    'Hefei Bank': 10717
  },
  BD: {
    'AL-ARAFAH ISLAMI BANK LTD.': 10916,
    'AB BANK LTD.': 10917,
    'BANGLADESH COMMERCE BANK LTD.': 10918,
    'BANGLADESH KRISHI BANK': 10919,
    'BANGLADESH SAMABAYA BANK LTD.': 10920,
    'BANGLADESH DEVELOPMENT BANK LTD.': 10921,
    'BASIC BANK LTD.': 10922,
    'BRAC BANK LTD.': 10923,
    'BANK AL-FALAH LTD': 10924,
    'BANK ASIA LTD.': 10925,
    'CITI BANK N A': 10926,
    'COMMERCIAL BANK OF CYLON': 10927,
    'DHAKA BANK LTD.': 10928,
    'DUTCH-BANGLA BANK LTD': 10929,
    'EASTERN BANK LTD.': 10930,
    'EXIM BANK LTD.': 10931,
    'FIRST SECURITY ISLAMI BANK LTD.': 10932,
    'HABIB BANK LTD.': 10933,
    'HONGKONG & SHANGHAI BANKING CORP.': 10934,
    'IFIC BANK LTD.': 10935,
    'ISLAMI BANK BANGLDESH LTD.': 10936,
    'JAMUNA BANK LTD.': 10937,
    'JANATA BANK LTD.': 10938,
    'MERCANTILE BANK LTD.': 10939,
    'MUTUAL TRUST BANK LTD.': 10940,
    'NATIONAL BANK LTD.': 10941,
    'NATIONAL BANK OF PAKISTAN': 10942,
    'NATIONAL CREDIT & COMMERCE BANK LTD.': 10943,
    'ONE BANK LTD.': 10944,
    'PRIME BANK LTD.': 10945,
    'PUBALI BANK LTD.': 10946,
    'RAJSHAHI KRISHI UNNAYAN BANK': 10947,
    'RUPALI BANK LTD.': 10948,
    'SHAHJALAL ISLAMI BANK LTD.': 10949,
    'SOCIAL ISLAMI BANK LTD': 10950,
    'SONALI BANK LTD.': 10951,
    'SOUTHEAST BANK LTD.': 10952,
    'STANDARD BANK LTD.': 10953,
    'STANDARD CHARTERED BANK': 10954,
    'STATE BANK OF INDIA': 10955,
    'THE CITY BANK LTD.': 10956,
    'ICB ISLAMIC BANK LTD': 10957,
    'THE PREMIER BANK LTD.': 10958,
    'TRUST BANK LTD.': 10959,
    'UNITED COMMERCIAL BANK LTD.': 10960,
    'UTTARA BANK LTD.': 10961,
    'WOORI BANK': 10962,
    'NRB COMMERCIAL BANK LTD.': 10963,
    'UNION BANK LTD.': 10964,
    'SBAC BANK LIMITED': 10965,
    'MEGHNA BANK LIMITED': 10966,
    'THE FARMERS BANK LIMITED': 10967,
    'MIDLAND BANK LIMITED': 10968,
    'NRB BANK LIMITED': 10969,
    'MODHUMOTI BANK LIMITED': 10970,
    'NRB GLOBAL BANK LIMITED': 10971,
    'AGRANI BANK LTD.': 10915
  },
  ID: {
    'BANK MALUKU': 10242,
    'BANK INA': 10275,
    BRI: 10193,
    MANDIRI: 10194,
    BNI: 10195,
    'BANK DANAMON': 10196,
    'BANK PERMATA': 10197,
    BCA: 10198,
    'BII / Maybank': 10199,
    'BANK PANIN': 10200,
    'CIMB NIAGA': 10201,
    'Bank UOB': 10202,
    'BANK OCBC NISP': 10203,
    CITIBANK: 10204,
    'BANK WINDU': 10205,
    'BANK ARTHA GRAHA': 10206,
    HSBC: 10207,
    'BANK OF TOKYO': 10208,
    'BANK DBS': 10209,
    STANCHAR: 10210,
    'BANK CAPITAL': 10211,
    ANZ: 10212,
    'Bank Of China Indonesia (BOCI)': 10213,
    'BANK BUMI ARTHA': 10214,
    EKONOMI: 10215,
    'BANK ANDA': 10216,
    RABOBANK: 10217,
    'JTrust Bank': 10219,
    MAYAPADA: 10220,
    'BANK BJB': 10221,
    'BANK DKI': 10222,
    'BPD DIY': 10223,
    'BANK JATENG': 10224,
    'BANK JATIM': 10225,
    'BANK JAMBI': 10226,
    'BANK ACEH': 10227,
    'BPD SUMUT': 10228,
    'BANK NAGARI': 10229,
    'BANK RIAU': 10230,
    'BANK SUMSELBABEL': 10231,
    'BANK LAMPUNG': 10232,
    'BANK KALSEL': 10233,
    'BPD KALBAR': 10234,
    'BPD KALTIM': 10235,
    'BANK KALTENG': 10236,
    'BANK SULSEL': 10237,
    'BANK SULUT': 10238,
    'BANK NTB': 10239,
    'BPD BALI': 10240,
    'BANK NTT': 10241,
    'BPD PAPUA': 10243,
    'BANK BENGKULU': 10244,
    'BANK SULTENG': 10245,
    'BANK SULTRA': 10246,
    BNP: 10247,
    'Bank Of India Indonesia': 10248,
    'BANK MUAMALAT': 10249,
    'BANK MESTIKA': 10250,
    'BANK SINARMAS': 10251,
    'BANK MASPION': 10252,
    'BANK GANESHA': 10253,
    'BANK ICBC INDONESIA': 10254,
    'Bank QNB': 10255,
    'BANK BTN': 10256,
    'BANK WOORI SAUDARA': 10257,
    BTPN: 10258,
    'Bank Victoria Syariah': 10260,
    'BANK BRI SYARIAH (BRIS)': 10261,
    'BJB SYARIAH': 10262,
    'BANK MEGA': 10263,
    'BANK BUKOPIN': 10264,
    'Bank Syariah Mandiri': 10265,
    'BANK JASA JAKARTA': 10266,
    'Bank Hana': 10267,
    'MNC Bank': 10268,
    'Bank BYB': 10269,
    'ATM AGRO': 10270,
    'BANK INDOMONEX': 10271,
    'BANK ROYAL IND': 10272,
    NOBUBANK: 10273,
    BSMI: 10274,
    'Bank Panin Syariah': 10652,
    'BANK SYARIAH BUKOPIN': 10277,
    'BANK S.SAMPOERNA': 10278,
    'BANK BARCLAY': 10279,
    'Bank Dinar': 10280,
    'B.KES.EKONOMI': 10281,
    'BCA SYARIAH': 10282,
    'BANK ARTOS': 10283,
    'BTPN SYARIAH': 10284,
    'BANK MAS': 10285,
    'BANK MAYORA': 10286,
    'BANK INDEX': 10287,
    'BANK PUNDI': 10288,
    'BANK CNB': 10289,
    'BANK VICTORIA': 10290,
    'BANK HARDA': 10291,
    'LSB (Lembaga Selain Bank) /BPR': 10292,
    'BPR KS': 10293,
    'INDOSAT (DOMPETKU)': 10297,
    'BANK EKA BUMI ARTHA': 10298,
    'TELKOMSEL (TCASH)': 10300,
    'Bank Agris': 10664,
    'CTBC INDONESIA': 10302,
    'Bank Royal': 10648,
    'Bank Artha Graha Internasional Tbk Pt': 10599,
    'HSBC Limited': 10600,
    'Bank Of Tokyo Mitsubishi Ufj Ltd. The': 10601,
    'Standard Chartered Bank': 10603,
    'Bank Capital Indonesia': 10604,
    'Rabobank Indonesia': 10605,
    'Bank Of China Jakarta Branch': 10607,
    'Pt Bank Bumi Arta Tbk': 10608,
    'Bank Ekonomi Raharja Pt.': 10609,
    'Bank Antardaerah': 10610,
    'Jtrust Bank': 10611,
    'Pt. Bank Mayapada International Tbk': 10612,
    'Bank Dki Pt.': 10613,
    'Bank BPD DIY': 10614,
    'Bank Jambi': 10616,
    'Bank Aceh': 10617,
    'Pt. Bank Sumut': 10618,
    'Bank Nagari': 10619,
    'Bank BPD Riau': 10620,
    'BPD Sumsel': 10621,
    'Bank Lampung': 10622,
    'BPD Kalsel': 10623,
    'BPD Kalbar': 10624,
    'Bank BPD Kal Teng': 10626,
    'Bank Sulut': 10627,
    'Bank NTB': 10628,
    'Bank Pembangunan Daerah Bali': 10629,
    'Bank Maluku': 10630,
    'Bank BPD Papua': 10631,
    'Bank Bengkulu': 10632,
    'BPD Sulteng': 10633,
    'Bank Sultra': 10634,
    'Bank Swadesi': 10635,
    'Bank Mestika': 10636,
    'Pt Bank Maspion Indonesia': 10637,
    'Bank Ganesha': 10638,
    'China Construction Bank Indonesia': 10639,
    'Kesawan': 10640,
    'Bank Jasa Jakarta': 10643,
    'Bank Icb Bumiputera': 10644,
    'Bank Yudha Bhakti': 10645,
    'Bank Agro': 10646,
    'Bank Sbi Indonesia': 10647,
    'Bank Syariah Mega Indonesia': 10650,
    'Bank INA': 10651,
    'Bank Sahabat Sampoerna': 10653,
    'Bank Kesejahteraan Ekonomi': 10654,
    'Bank Artos': 10655,
    'Pt. Bank Mayora': 10656,
    'Bank Index': 10657,
    'Centra Tama Nasional': 10658,
    'Pt. Bank Victoria Internasional Tbk': 10659,
    'Bank HARDA': 10660,
    'BPR LSB': 10661,
    'BANK BPR KS': 10662,
    'Bank BPR EKA': 10663,
    'Bank Chinatrust Indonesia Pt': 10665,
    'Bank Commonwealth': 10666,
    'The Royal Bank of Scotland': 11179,
    'Bank Jabar Banten Syariah': 11180,
    'Bank OKE Indonesia': 11182,
    'Bank Multi Arta Sentosa': 11183,
    'Bank Mantap': 11184,
    'COMMBANK': 10303
  },
  PH: {
    'Citibank': 10498,
    'Chinabank Savings': 10484,
    'Bank of Commerce': 10480,
    'Allied Bank': 10477,
    'Allied Bank Savings (PNB Savings)': 10478,
    'Asia United Bank': 10479,
    'BDO (Banco de Oro)': 10481,
    'BPI (Bank of the Philippine Islands)': 10482,
    'Chinabank': 10483,
    'East-West Bank': 10485,
    'Landbank': 10486,
    'Metrobank': 10487,
    'PNB (Phil. National Bank)': 10488,
    'RCBC (Rizal Commercial Bank)': 10490,
    'RCBC Savings': 10491,
    'Security Bank': 10492,
    'Union Bank': 10493,
    'UCPB': 10494,
    'UCPB Savings': 10495,
    'Chinatrust': 10497,
    'Citibank Savings': 10499,
    'Citystate Savings': 10500,
    'Equicom Savings': 10502,
    'HSBC': 10503,
    'HSBC Savings': 10504,
    'Malayan Bank': 10505,
    'May Bank': 10506,
    'PBCOM (Phil. Bank of Communications)': 10508,
    'Philtrust': 10509,
    'Philippine Veterans Bank': 10510,
    'Postal Bank': 10511,
    'Robinsons Bank': 10512,
    'Security Bank Savings': 10513,
    'Standard Chartered': 10514,
    'Philippine Business Bank': 10577,
    'Planters Bank': 10580,
    'First Allied Bank': 10582,
    'PSBank': 10489,
    'BPI Family': 10587,
    'Industrial Bank of Korea': 11799,
    'BDO Network Bank': 11203
  },
  JP: {
    'Anywhere Payout': 1048,
    'Mitsubishi Tokyo UFJ Bank (미쓰비시도쿄UFJ은행)': 10360,
    'Sumitomo Mitsui Banking Corporation (미츠이스미토모은행)': 10361,
    'Mizuho Bank (미즈호은행)': 10359,
    'Resona Bank (리소나은행)': 10358,
    'Saitama Resona Bank (사이타마리소나은행)': 10425,
    'Japan Post Bank (우체국은행)': 10357,
    'Japan Seven Bank ATM (Japan Seven Bank ATM)': 1043,
    'Yachiyo Bank (야치요은행)': 10476,
    'Miyazaki Taiyo Bank (미야자키타이요은행)': 10473,
    'Kochi Bank (고치은행)': 10467,
    'Momiji Bank (모미지은행)': 10462,
    'Minato Bank (미나토은행)': 10460,
    'Kansai Urban Bank (간사이어반은행)': 10458,
    'Nagoya Bank (나고야은행)': 10455,
    'Keiyo Bank (케이요은행)': 10445,
    'Shiga Bank (시가은행)': 10433,
    'Rakuten Bank (라쿠텐은행)': 10424,
    'Ryukyu Bank (류큐은행)': 10420,
    'Okinawa Bank (오키나와은행)': 10411,
    'Kagoshima Bank (가고시마은행)': 10410,
    'Higo Bank (히고은행)': 10408,
    'Shinwa Bank (신와은행)': 10407,
    'Nishi Nippon City Bank (니시닛폰시티은행)': 10403,
    'Ikeda Senshu Bank (이케다센슈은행)': 10395,
    '105 Bank (105은행)': 10392,
    '16 Bank (16은행)': 10390,
    'Ogaki Koryo Bank (오오가키쿄리츠은행)': 10389,
    'Fukui Bank (후쿠이은행)': 10386,
    'Musashino Bank (무사시노은행)': 10379,
    'Ashikaga Bank (아시카가은행)': 10377,
    'Yokohama Bank (요코하마은행)': 10375,
    'Hokuriku Bank (호쿠리쿠은행)': 10373,
    'Chugoku Bank (추고쿠은행)': 10372,
    'Gunma Bank (군마은행)': 10371,
    '82 Bank (82은행)': 10370,
    'Kyoto Bank (교토은행)': 10369,
    '77 Bank (77은행)': 10367,
    'Hokuyo Bank (호쿠요은행)': 10366,
    'Shizuoka Bank (시즈오카은행)': 10364,
    'Chiba Bank (치바은행)': 10362,
    'Fukuoka Bank (후쿠오카은행)': 10363,
    'Sony Bank (소니은행)': 12192,
    'Seven Bank (세븐은행)': 11899,
    'USD Anywhere Payout': 12820,
    'Aozora Bank (아오조라은행)': 10435,
    'Aeon Bank (이온은행)': 10429,
    'Sumitomo Mitsui SBI Net Bank (스미신SBI넷은행)': 10427,
    'Mie Bank (미에은행)': 10391,
    'Shinsei Bank (신세이은행)': 10374,
    'Joyo Bank (조요은행)': 10365,
    'HSBC Japan (HSBC 일본)': 13056,
    'Shinhan Bank Japan (신한은행 일본)': 13055,
    'KEB HANA BANK (하나은행 일본)': 10437,
  },
  TH: {
    'AGRICULTURE & AGRICULTL COOP': 10517,
    'BANGKOK BANK': 10518,
    'BANK OF AYUDHYA': 10519,
    'CIMB Thai Bank Public Company Ltd': 10520,
    'GOVERNMENT HOUSING BANK': 10521,
    'GOVERNMENT SAVING BANK': 10522,
    'KASIKORN BANK': 10523,
    'KRUNG THAI BANK': 10524,
    'SIAM COMMERCIAL BANK': 10525,
    'THAI MILITARY BANK': 10526,
    'THANACHART BANK': 10527,
    'UNITED OVERSEA BANK (Thai) LTD': 10528,
    'Bank of Thailand': 10667,
    'ABN-AMRO Bank N.V. Bangkok Branch': 10668,
    'Bank of Tokyo-Mitsubishi Ltd. Bangkok': 10669,
    'TMB Bank Public Company Limited': 10670,
    'Siam City Bank Public Company Limited': 10671,
    'Citibank N.A.': 10672,
    'Sumitomo Mitsui Banking Corporation': 10673,
    'Standard Chartered Bank Thai PCL': 10674,
    'Mega International Commercial Bank PCL': 10675,
    'Bank of America National Association': 10676,
    Calyon: 10677,
    'Hong Kong & Shanghai Corporation Limited': 10678,
    'Deutsche Bank Aktiengesellschaft': 10679,
    'Mizuho Corporate Bank Limited': 10680,
    'Islamic Bank of Thailand': 10681,
    'Tisco Bank Public Company Limited': 10682,
    'AIG Retail Bank Public Company Limited': 10683,
    'Kiatnakin Bank Public Company Limited': 10684,
    'ACL Bank Public Company Limited': 10685,
    'The Thai Credit Retail Bank PCL': 10686,
    'Land and Houses Bank PCL': 10687
  },
  VN: {
    Agribank: 10529,
    BIDV: 10530,
    'Dong A Bank': 10531,
    Vietinbank: 10532,
    Sacombank: 10533,
    Saigonbank: 10534,
    ABBank: 10535,
    OceanBank: 10536,
    SeABank: 10537,
    PVcomBank: 10538,
    'PG Bank': 10539,
    'GP Bank': 10540,
    KienLongBank: 10541,
    'Vietcapital Bank': 10542,
    VietBank: 10543,
    VRBank: 10544,
    Eximbank: 10545,
    TPBank: 10546,
    SHB: 10547,
    HDBank: 10548,
    MBBank: 10549,
    VPBank: 10550,
    Techcombank: 10551,
    OCB: 10552,
    NCB: 10553,
    HLBVN: 10554,
    BVB: 10555,
    'Public Bank VN': 10556,
    SCB: 10557,
    ANZ: 10558,
    'HSBC Bank Vietnam': 10559,
    'Vietnam Maritime Commercial Stock Bank': 10560,
    'MHB Bank': 10561,
    'Nam A Commercial Join Stock Bank': 10562,
    'Southern Commercial Joint Stock Bank': 10563,
    'NH Moneygram': 10564,
    'NH Chinh Sach XHVN': 10565,
    VDB: 10566,
    'Co-op Bank': 10567,
    'Viet A Bank': 10568,
    VNCB: 10569,
    Vietcombank: 10570,
    ACB: 10571,
    VIB: 10572,
    'BacA Bank': 10573,
    Timo: 10574,
    'Shinhan Bank Vietnam (SHBVN)': 10771,
    LienVietPostBank: 10770,
    'Woori bank Vietnam': 11178,
    'Standard Chartered Bank (Vietnam) Limited': 11377
  },
  PK: {
    'NATIONAL BANK OF PAKISTAN': 10773,
    'BANK OF PUNJAB': 10774,
    'ALLIED BANK LIMITED': 10775,
    'Habib Bank Ltd': 10776,
    'MUSLIM COMERCIAL BANK': 10777,
    'Askari Bank Limited': 10778,
    'BANK ALHABIB': 10779,
    'BANK ALFALAH LIMITED': 10780,
    'Burj Bank': 10781,
    'Dubai Islamic Bank Ltd': 10782,
    'FAYSAL BANK LIMITED': 10783,
    'HABIB METROPOLITAN BANK': 10784,
    'MEEZAN BANK LIMITED': 10785,
    'SAMBA BANK': 10786,
    'SILK BANK': 10787,
    'SONERI BANK LIMITED': 10788,
    'NIB BANK': 10789,
    'CITI BANK': 10790,
    'Sindh Bank': 10791,
    'KASB BANK LIMITED': 10792,
    'FIRST WOMEN BANK LIMITED': 10793,
    'BARCLAYS BANK LIMITED': 10794,
    'Oman International Bank': 10795,
    'SUMMIT BANK': 10796,
    'BANK ISLAMI PAKISTAN LIMITED': 10797,
    'HSBC Bank Limited': 10798,
    'UNITED BANK LIMITED': 10799,
    'BANK OF KHYBER': 10800,
    'BANK OF AZAD JAMMU KASHMIR': 10801,
    'FIRST MICRO FINANCE BANK LIMITED': 10802,
    'Model Bank': 10803,
    'JAHANGIR SIDDIQUI BANK LTD': 10804,
    'Tameer microfinance bank': 10805,
    'Al Barka Bank': 11165,
    'Standard Chartered Bank': 11167,
    'Apna Microfinance Bank': 11168,
    'U Microfinance Bank': 11169,
    ICBC: 11170,
    NRSP: 11171,
    'MCB Islamic Bank': 11172,
    'JS Bank Limited': 11173,
    'Zarai Taraqiati Bank': 11174,
    'FINCA Microfinance Bank': 11175,
    'Industrial and Commercial Bank of China': 11176,
    'Deutsche Bank Ltd': 11177,
    Waseela: 10772
  },
  NP: {
    'APEX DEVELOPMENT BANK LTD.': 10806,
    'BANK OF KATHMANDU': 10807,
    'MEGA BANK NEPAL LTD.': 10808,
    'DEVA BIKAS BANK LIMITED': 10809,
    'TINAU DEVLOPMENT BANK LTD.': 10810,
    'TOURISM DEVELOPMENT BANK LTD.': 10811,
    'TRIVENI BIKAS BANK LTD.': 10812,
    'GREEN DEVELOPMENT BANK': 10813,
    'YETI DEVELOPMENT BANK LTD.': 10814,
    'SHINE RESUNGA DEVELOPMENT BANK LTD.': 10815,
    'MUKTINATH BIKAS BANK LTD.': 10816,
    'GUHESWORI MERCHANT BANKING & FINANCE': 10817,
    'BHRIKUTEE DEVELOPMENT BANK LTD.': 10818,
    'SIDDHARTHA DEVELOPMENT BANK LTD.': 10819,
    'JHIMRUK BIKAS BANK': 10820,
    'RELIABLE DEVELOMENT BANK': 10821,
    'MANASLU BIKAS BANK': 10822,
    'CITIZENS BANK INTERNATIONAL LTD.': 10823,
    'PRABHU BANK LTD.': 10824,
    'SAPTAKOSHI DEVELOPMENT BANK LTD': 10825,
    'KASTHAMANDAP DEVELOPMENT BANK': 10826,
    'CITY DEVELOPMENT BANK LIMITED': 10827,
    'VIBOR BIKAS BANK': 10828,
    'KUMARI BANK LTD': 10829,
    'SANIMA BANK LTD': 10830,
    'NMB BANK LIMITED': 10831,
    'KAMANA BIKAS BANK': 10832,
    'Ace Development Bank Ltd': 10833,
    'Agricultural Development Bank': 10834,
    'Alpine Development Bank Ltd': 10835,
    'Annapurna Bikash Bank': 10836,
    'Axel Bank': 10837,
    'Bageshwori Development Bank Ltd': 10838,
    'Bank of Asia Ltd': 10839,
    'Biratlaxmi Bikas Bank Ltd': 10840,
    'Bishwa Bikash Bank Limited': 10841,
    'Business Universal Development Bank Ltd': 10842,
    'Century Commerical Bank Ltd': 10843,
    'Civil Bank Ltd.': 10844,
    'Clean Energy Development Bank Ltd': 10845,
    'Commerz and Trust Bank Nepal Ltd.': 10846,
    'Ekata Bikas Bank': 10847,
    'Everest Bank Limited': 10848,
    'Excel Development Bank Ltd': 10849,
    'Federal Bank': 10850,
    'Gandaki Bikas Bank Ltd': 10851,
    'Garima Bikas Bank Limited': 10852,
    'Gaurishankar Development Bank Ltd': 10853,
    'Global IME Bank Limited': 10854,
    'Grand Bank Nepal Ltd': 10855,
    'Gulmi Bikas Bank Limited': 10856,
    'H and B Development Bank Limited': 10857,
    'Hamro Bikas Bank': 10858,
    'Himalayan Bank Limited': 10859,
    'Infrastructure Development Bank Ltd': 10860,
    'Innovative Development Bank Ltd': 10861,
    'International Development Bank': 10862,
    'Janata Bank Nepal Ltd': 10863,
    'Jyoti Bikash Bank': 10864,
    'Kabeli Bikas Bank Ltd': 10865,
    'Kailash Bikas Bank Limited': 10866,
    'Kanchan Development Bank Ltd': 10867,
    'Karnali Bikash Bank': 10868,
    'Kist Bank Ltd.': 10869,
    'Laliguraas Development Bank': 10870,
    'Laxmi Bank Limited.': 10871,
    'Lumbini Bank Limited': 10872,
    'Machhapucharre Bank Ltd.': 10873,
    'Mahakali Bikas Bank Ltd': 10874,
    'Malika Vikas Bank': 10875,
    'Manakamana Development Bank': 10876,
    'Nabil Bank Limited': 10877,
    'NDEP Development Bank Ltd': 10878,
    'Nepal Bangladesh Bank Limited': 10879,
    'Nepal Bank Limited': 10880,
    'Nepal Credit & Commerce Bank Limited': 10881,
    'Nepal CSI Development Bank Limited': 10882,
    'Nepal Industrial & Commercial Bank LTD.': 10883,
    'Nepal Investment Bank Limited': 10884,
    'Nepal SBI Bank Limited.': 10885,
    'Nilgiri Bikas Bank Ltd': 10886,
    'Nirdhan Uthhan Bank': 10887,
    'Paschimanchal Development Bank Ltd': 10888,
    'Pathibhara Bikas Bank Limited': 10889,
    'Prime Commercial Bank': 10890,
    'Public Development Bank Ltd': 10891,
    'Rapti Bheri Development Bank': 10892,
    'Rara Bikash Bank': 10893,
    'Rastriya Banijya Bank': 10894,
    'Resunga Bikas Bank Limited': 10895,
    'Rising Development Bank Ltd': 10896,
    'Sahayogi Development Bank Limited': 10897,
    'Sewa Bikas Bank Ltd': 10898,
    'Shangri-la Development Bank Ltd': 10899,
    'Siddharatha Bank Limited': 10900,
    'Sindhu Bikas Bank Ltd': 10901,
    'Standard Chartered Bank Limited': 10902,
    'Sunrise Bank Limited': 10903,
    'Supreme Development Bank': 10904,
    'Western Development Bank Limited': 10905,
    'MAHALAXMI BIKAS BANK LTD': 10906,
    'MISSION DEVELOPMENT BANK.': 10907,
    'NIC ASIA BANK LTD': 10908,
    'OM DEVELOPMENT BANK LTD': 10909,
    'PURNIMA BIKASH BANK': 10910,
    'SAJHA BIKAS BANK': 10911,
    'SHANGRILA BIKAS BANK LIMITED': 10912,
    'TINAU BIKAS BANK': 10913,
    'UNITED FINANCE LTD': 10914
  },
  LK: {
    'SAMPATH BANK': 11102,
    'Hatton National Bank': 11078,
    'AMANA BANK LTD': 11079,
    'AXIS BANK LTD': 11080,
    'Bank Of Ceylon': 11081,
    'CARGILLS BK': 11082,
    'Citizens Development Bank': 11083,
    'CITY BANK': 11084,
    'Commercial Bank': 11085,
    'DEUTSCHE BANK AG': 11086,
    'DFCC Bank': 11087,
    'HABIB BANK LTD': 11088,
    HDFC: 11089,
    HSBC: 11090,
    'ICICI BANK': 11091,
    'INDIAN BANK': 11092,
    'INDIAN OVERSEAS BANK': 11093,
    'MCB BANK LTD': 11094,
    'NATIONAL DEVELOPMENT BANK PLC': 11095,
    'NATIONAL SAVING BANK': 11096,
    'NATIONAL TRUST BANK': 11097,
    'PAN ASIA BANKING CORP LTD': 11098,
    'PEOPLES BANK': 11099,
    'PUBLIC BANK': 11100,
    'RURAL DEVELOPMENT BANK': 11101,
    'SANASA DEVELOPMENT BANK': 11105,
    'SEYLAN BANK': 11106,
    'STATE MORTGAGE & INVESTMENT BANK': 11107,
    'UNION BANK OF COLOMBO': 11108,
    'UNION BANK PAKISTAN': 11109,
    'ALLIANCE FINANCE COMPANY PLC': 11110,
    'CENTRAL FINANCE': 11111,
    'COMMERCIAL LEASING & FINANCE': 11112,
    'LANKA ORIX FINANCE': 11113,
    'LB FINANCE': 11114,
    'MBSL & FINANCE PLC': 11115,
    'MERCANTILE INVESTMENT & FINANCE PLC': 11116,
    "PEOPLE'S LEASING & FINANCE PLC": 11117,
    'REGIONAL DEVELOPMENT BANK': 11118,
    'SENKADAGALA FINANCE': 11119,
    'Muslim Commercial Bank LTD': 11162,
    'STANDARD CHARTERED BANK': 11104,
    'STATE BANK OF INDIA': 11103
  },
  SG: {
    'DBS Bank': 11348,
    'OCBC Bank': 11398,
    'UOB Bank': 11399,
    'POSB Bank': 11400,
    'HSBC Bank': 11401,
    'Standard Chartered Bank': 11402,
    'Malayan Banking (Maybank)': 11403,
    'Bank of China': 11404,
    Citibank: 11405,
    'ANZ Bank': 11406,
    'The Bank of Tokyo-Mitsubishi UFJ': 11407,
    'BNP Paribas': 11408,
    'CIMB Bank': 11409,
    'CréDIT AGRICOLE CORPORATE AND INVESTMENT BANK': 11410,
    'HL bank': 11411,
    'Mizuho Bank': 11412,
    'RHB Bank': 11413,
    'Sumitomo Mitsui Banking Corporation': 11414
  },
  KH: {
    'All Banks / Payment System: KH': 53, // previous KH all banks label
    'Foreign Trade Bank of Cambodia (USD)': 11495,
    'Cambodia Commercial Bank LTD (USD)': 11496,
    'Cambodia Public Bank PLC (USD)': 11497,
    'Canadia Bank PLC (USD)': 11498,
    'Krung Thai Bank Public Co LTD PP Branch (USD)': 11499,
    'Cambodia Asia Bank LTD (USD)': 11500,
    'May Bank (Cambodia) PLC (USD)': 11501,
    'Union Commercial Bank PLC (USD)': 11502,
    'Cambodia Mekong Bank Public LTD (USD)': 11503,
    'Advanced Bank of Asia LTD (USD)': 11504,
    'Rural Development Bank (USD)': 11505,
    'First Commercial Bank, Phnom Penh Branch (USD)': 11506,
    'Vattanac Bank (USD)': 11508,
    'J Trust Royal Bank Ltd (USD)': 11509,
    'Shinhan Khmer Bank (USD)': 11510,
    'SATHAPANA BANK PLC (USD)': 11511,
    'Phnom Penh Commercial Bank (USD)': 11512,
    'Booyong Khmer Bank (USD)': 11513,
    'SACOM Bank (Cambodia) PLC (USD)': 11514,
    'Kookmin Bank Cambodia PLC (USD)': 11515,
    'Bank for Investment and Development of Cambodia Plc (USD)': 11516,
    'ICBC Limited Phnom Penh Branch (USD)': 11517,
    'Agri Bank of Cambodia (Vietnam Bank) (USD)': 11518,
    'Bank of China Limted Phnom Penh Branch (USD)': 11519,
    'CIMB Bank PLC (USD)': 11520,
    'Mega International Bank Co LTD PP Branch (USD)': 11521,
    'MB Bank PLC Phnom Penh Branch (USD)': 11522,
    'SHB PLC Phnom Penh Branch (USD)': 11523,
    'Taiwan Cooperative Bank PP Branch (USD)': 11524,
    'Hong Leong Bank (Cambodia) PLC (USD)': 11525,
    'RHB Indochina (Cambodia) PLC (USD)': 11526,
    'Cambodia Post Bank PLC (USD)': 11527,
    'Cathay United Bank (Cambodia) Corporation Bank (USD)': 11528,
    'Phillip Bank PLC (USD)': 11529,
    'Bangkok Bank Plc Cambodia Branch (USD)': 11530,
    'KASIKORN BANK (USD)': 11531,
    'Bred Bank PLC (USD)': 11532,
    'National Bank of Cambodia (USD)': 11533,
    'Chief (Cambodia) Commercial Bank Plc. (USD)': 11534,
    'Prince Bank Plc. (USD)': 11535,
    'Industrial Bank of Korea Phnom Penh Branch. (USD)': 11536,
    'BIC (Cambodia ) Bank Plc. (USD)': 11537,
    'CHIP MONG COMMERCIAL BANK PLC (USD)': 11538,
    'AMK MFI (USD)': 11539,
    'WB FINANCE (USD)': 11540,
    'HATHAKASEKAR MFI (USD)': 11541,
    'AMRET MFI (USD)': 11542,
    'KREDIT MFI (USD)': 11543,
    'PRASAC MFI (USD)': 11544,
    'LOLC MFI (USD)': 11545,
    'Foreign Trade Bank of Cambodia': 11546,
    'Cambodia Commercial Bank LTD': 11547,
    'Cambodia Public Bank PLC': 11548,
    'Canadia Bank PLC': 11549,
    'Krung Thai Bank Public Co LTD PP Branch': 11550,
    'Cambodia Asia Bank LTD': 11551,
    'May Bank (Cambodia) PLC': 11552,
    'Union Commercial Bank PLC': 11553,
    'Cambodia Mekong Bank Public LTD': 11554,
    'Advanced Bank of Asia LTD': 11555,
    'Rural Development Bank': 11556,
    'First Commercial Bank, Phnom Penh Branch': 11557,
    'Vattanac Bank': 11559,
    'J Trust Royal Bank Ltd': 11560,
    'Shinhan Khmer Bank': 11561,
    'SATHAPANA BANK PLC': 11562,
    'Phnom Penh Commercial Bank': 11563,
    'Booyong Khmer Bank': 11564,
    'SACOM Bank (Cambodia) PLC': 11565,
    'Kookmin Bank Cambodia PLC': 11566,
    'Bank for Investment and Development of Cambodia Plc': 11567,
    'ICBC Limited Phnom Penh Branch': 11568,
    'Agri Bank of Cambodia (Vietnam Bank)': 11569,
    'Bank of China Limted Phnom Penh Branch': 11570,
    'CIMB Bank PLC': 11571,
    'Mega International Bank Co LTD PP Branch': 11572,
    'MB Bank PLC Phnom Penh Branch': 11573,
    'SHB PLC Phnom Penh Branch': 11574,
    'Taiwan Cooperative Bank PP Branch': 11575,
    'Hong Leong Bank (Cambodia) PLC': 11576,
    'RHB Indochina (Cambodia) PLC': 11577,
    'Cambodia Post Bank PLC': 11578,
    'Cathay United Bank (Cambodia) Corporation Bank': 11579,
    'Phillip Bank PLC': 11580,
    'Bangkok Bank Plc Cambodia Branch': 11581,
    'KASIKORN BANK': 11582,
    'Bred Bank PLC': 11583,
    'National Bank of Cambodia': 11584,
    'Chief (Cambodia) Commercial Bank Plc.': 11585,
    'Prince Bank Plc.': 11586,
    'Industrial Bank of Korea Phnom Penh Branch.': 11587,
    'BIC (Cambodia ) Bank Plc.': 11588,
    'CHIP MONG COMMERCIAL BANK PLC': 11589,
    'AMK MFI': 11590,
    'WB FINANCE': 11591,
    'HATHAKASEKAR MFI': 11592,
    'AMRET MFI': 11593,
    'KREDIT MFI': 11594,
    'PRASAC MFI': 11595,
    'LOLC MFI': 11596
  },
  HK: {
    'Bank of China Hong Kong': 10189,
    'DBS Bank Hong Kong': 10190,
    'HSBC Hong Kong': 10191,
    'Standard Chartered Hong Kong': 10192,
    'Citibank N.A.': 11415,
    'Citibank (Hong Kong) Limited': 11416,
    'Hang Seng Bank Ltd.': 11417,
    'Chong Hing Bank Limited': 11418,
    'CMB Wing Lung Bank Limited': 11419,
    'Industrial and Commercial Bank of China (Asia)': 11420,
    'Nanyang Commercial Bank': 11421,
    'China Construction Bank (Asia) Corporation Limited': 11422,
    'Agricultural Bank of China Limited': 11423,
    'China Minsheng Banking Corp. Ltd': 11424,
    'ABN AMRO Bank N.V. (USD)': 11901,
    'Agricultural Bank of China Limited (USD)': 11902,
    'Allahabad Bank (USD)': 11903,
    'Australia and New Zealand Banking Group Ltd. (USD)': 11904,
    'Axis Bank Limited (USD)': 11905,
    'Banca Monte dei Paschi di Siena S.p.A. Hong Kong Branch (USD)': 11906,
    'Banco Bilbao Vizcaya Argentaria, S.A. (USD)': 11907,
    'Banco Santander S.A. (USD)': 11908,
    'Bangkok Bank Public Company Limited (USD)': 11909,
    'Bank J. Safra Sarasin Ltd. (USD)': 11910,
    'Bank Julius Baer & Co. Ltd. (USD)': 11911,
    'Bank of America, N.A. (USD)': 11912,
    'Bank of Baroda (USD)': 11913,
    'Bank of China (Hong Kong) Limited (USD)': 11914,
    'Bank of China Limited, Hong Kong Branch (USD)': 11915,
    'Bank of Communications (Hong Kong) Limited (USD)': 11916,
    'Bank of Communications Co., Ltd. (USD)': 11917,
    'Bank of India (USD)': 11918,
    'Bank of Montreal (USD)': 11919,
    'Bank of Singapore Limited (USD)': 11920,
    'Bank of Taiwan (USD)': 11921,
    'Bank SinoPac (USD)': 11922,
    'Barclays Bank Plc. (USD)': 11923,
    'BDO Unibank, Inc. (USD)': 11924,
    'BNP Paribas (USD)': 11925,
    'BNP Paribas Securities Services (USD)': 11926,
    'CA Indosuez (Switzerland) SA (USD)': 11927,
    'Canadian Imperial Bank of Commerce (USD)': 11928,
    'Canara Bank (USD)': 11929,
    'Cathay Bank (USD)': 11930,
    'Cathay United Bank Company, Limited (USD)': 11931,
    'Chang Hwa Commercial Bank, Ltd. (USD)': 11932,
    'China CITIC Bank International Limited (USD)': 11933,
    'China Construction Bank (Asia) Corporation Limited (USD)': 11934,
    'China Construction Bank Corporation (USD)': 11935,
    'China Development Bank (USD)': 11936,
    'China Everbright Bank Co., Ltd. (USD)': 11937,
    'China Merchants Bank Co., Ltd. (USD)': 11938,
    'China Minsheng Banking Corp., Ltd. (USD)': 11939,
    'China Zheshang Bank Co., Ltd. (USD)': 11940,
    'Chiyu Banking Corporation Ltd. (USD)': 11941,
    'Chong Hing Bank Limited (USD)': 11942,
    'CIMB Bank Berhad (USD)': 11943,
    'Citibank (Hong Kong) Limited (USD)': 11944,
    'Citibank, N.A. (USD)': 11945,
    'CMB Wing Lung Bank Limited (USD)': 11946,
    'Commerzbank AG (USD)': 11947,
    'Commonwealth Bank of Australia (USD)': 11948,
    'Coöperatieve Rabobank U.A. (USD)': 11949,
    'Coutts & Co. Ltd. (USD)': 11950,
    'Credit Agricole Corporate and Investment Bank (USD)': 11951,
    'Credit Industriel et Commercial (USD)': 11952,
    'Credit Suisse AG (USD)': 11953,
    'CTBC Bank Co., Ltd. (USD)': 11954,
    'Dah Sing Bank, Ltd. (USD)': 11955,
    'DBS Bank (Hong Kong) Limited (USD)': 11956,
    'DBS Bank Ltd, Hong Kong Branch (USD)': 11957,
    'Deutsche Bank AG (USD)': 11958,
    'DZ BANK AG Deutsche Zentral-Genossenschaftsbank (USD)': 11959,
    'E.Sun Commercial Bank, Ltd. (USD)': 11960,
    'East West Bank (USD)': 11961,
    'EFG Bank AG (USD)': 11962,
    'Erste Group Bank AG (USD)': 11963,
    'Far Eastern International Bank (USD)': 11964,
    'First Abu Dhabi Bank PJSC (USD)': 11965,
    'First Commercial Bank (USD)': 11966,
    'Fubon Bank (Hong Kong) Limited (USD)': 11967,
    'Hang Seng Bank Ltd. (USD)': 11968,
    'HDFC Bank Limited (USD)': 11969,
    'Hong Leong Bank Berhad (USD)': 11970,
    'HSBC Bank USA, N.A. (USD)': 11971,
    'HSBC Private Bank (Suisse) SA (USD)': 11972,
    'Hua Nan Commercial Bank, Ltd. (USD)': 11973,
    'ICBC Standard Bank PLC (USD)': 11974,
    'ICICI Bank Limited (USD)': 11975,
    'Indian Overseas Bank (USD)': 11976,
    'Industrial and Commercial Bank of China (Asia) Limited (USD)': 11977,
    'Industrial and Commercial Bank of China Limited (USD)': 11978,
    'Industrial Bank Co., Ltd. (USD)': 11979,
    'Industrial Bank of Korea (USD)': 11980,
    'ING Bank N.V. (USD)': 11981,
    'Intesa Sanpaolo S.p.A. (USD)': 11982,
    'JPMorgan Chase Bank, N.A. (USD)': 11983,
    'KBC Bank N.V., Hong Kong Branch (USD)': 11984,
    'KEB Hana Bank (USD)': 11985,
    'Kookmin Bank (USD)': 11986,
    'Land Bank of Taiwan Co., Ltd. (USD)': 11987,
    'LGT Bank AG (USD)': 11988,
    'Livi VB Limited (USD)': 11989,
    'Macquarie Bank Limited (USD)': 11990,
    'Malayan Banking Berhad (Maybank) (USD)': 11991,
    'Mashreq Bank - Public Shareholding Company (USD)': 11992,
    'Mega International Commercial Bank Co., Ltd. (USD)': 11993,
    'Melli Bank plc (USD)': 11994,
    'Mitsubishi UFJ Trust and Banking Corporation (USD)': 11995,
    'Mizuho Bank, Ltd., Hong Kong Branch (USD)': 11996,
    'MUFG Bank, Ltd. (USD)': 11997,
    'Nanyang Commercial Bank, Ltd. (USD)': 11998,
    'National Australia Bank Limited (USD)': 11999,
    'National Bank of Pakistan (USD)': 12000,
    'Natixis (USD)': 12001,
    'NatWest Markets N.V. (USD)': 12002,
    'NatWest Markets Plc (USD)': 12003,
    'O-Bank Co., Ltd. (USD)': 12004,
    'OCBC Wing Hang Bank Limited (USD)': 12005,
    'Oversea-Chinese Banking Corporation Ltd. (USD)': 12006,
    'Philippine National Bank (USD)': 12007,
    'Pictet & Cie (Europe) S.A. (USD)': 12008,
    'PT. Bank Negara Indonesia (Persero) Tbk. (USD)': 12009,
    'Public Bank (Hong Kong) Limited (USD)': 12010,
    'Punjab National Bank (USD)': 12011,
    'Royal Bank of Canada (USD)': 12012,
    'Shanghai Commercial Bank Ltd. (USD)': 12013,
    'Shanghai Pudong Development Bank Co., Ltd. (USD)': 12014,
    'Shinhan Bank (USD)': 12015,
    'Skandinaviska Enskilda Banken AB (USD)': 12016,
    'Societe Generale (USD)': 12017,
    'Standard Chartered Bank (Hong Kong) Limited (USD)': 12018,
    'Standard Chartered Bank Hong Kong Branch (USD)': 12019,
    'State Bank of India (USD)': 12020,
    'State Street Bank and Trust Company (USD)': 12021,
    'Sumitomo Mitsui Banking Corporation (USD)': 12022,
    'Sumitomo Mitsui Trust Bank, Limited (USD)': 12023,
    'Svenska Handelsbanken AB (publ) (USD)': 12024,
    'Tai Sang Bank Limited (USD)': 12025,
    'Tai Yau Bank Ltd. (USD)': 12026,
    'Taipei Fubon Commercial Bank Co., Ltd. (USD)': 12027,
    'Taishin International Bank Co., Ltd. (USD)': 12028,
    'Taiwan Business Bank, Ltd. (USD)': 12029,
    'Taiwan Cooperative Bank, Ltd. (USD)': 12030,
    'Taiwan Shin Kong Commercial Bank Co., Ltd. (USD)': 12031,
    'The Bank of East Asia, Limited (USD)': 12032,
    'The Bank of New York Mellon (USD)': 12033,
    'The Bank of Nova Scotia (USD)': 12034,
    'The Chiba Bank Ltd. (USD)': 12035,
    'The Chugoku Bank Limited (USD)': 12036,
    'The Hachijuni Bank, Ltd. (USD)': 12037,
    'HSBC The Hongkong and Shanghai Banking Corporation Limited (USD)': 12038,
    'The Shanghai Commercial & Savings Bank, Ltd. (USD)': 12039,
    'The Shiga Bank, Ltd. (USD)': 12040,
    'The Shizuoka Bank Ltd. (USD)': 12041,
    'The Toronto-Dominion Bank (USD)': 12042,
    'UBS AG, Hong Kong (USD)': 12043,
    'UCO Bank (USD)': 12044,
    'UniCredit Bank AG (USD)': 12045,
    'Union Bancaire Privée, UBP SA (USD)': 12046,
    'Union Bank of India (USD)': 12047,
    'United Overseas Bank Ltd. (USD)': 12048,
    'Wells Fargo Bank, N.A., Hong Kong Branch (USD)': 12049,
    'Westpac Banking Corporation (USD)': 12050,
    'Woori Bank (USD)': 12051,
    'Yuanta Commercial Bank Co., Ltd. (USD)': 12052,
    'ZA Bank Limited (USD)': 12053
  },
  TR: {
    'ADABANK A.S.': 11597,
    'AKBANK T.A.S.': 11598,
    'AKTIF YATIRIM BANKASI A.S.': 11599,
    'ALBARAKA TURK KATILIM BANKASI A.S.': 11600,
    'ALTERNATIFBANK A.S.': 11601,
    'ANADOLUBANK A.S.': 11602,
    'ARAP TÜRK BANKASI A.S.': 11603,
    'BANK OF CHINA TURKEY': 11604,
    'BANK OF TOKYO-MITSUBISHI UFJ TURKEY A.S.': 11605,
    'BANKA MELLAT TÜRKIYE A.S': 11606,
    'BANKPOZITIF KREDI VE KALK.BANK.A.S.': 11607,
    'BIRLESIK FON BANKASI A.S.': 11608,
    'BURGAN BANK A.S.': 11609,
    'CITIBANK A.S.': 11610,
    'DENIZ BANK A.S.': 11611,
    'DEUTSCHE BANK A.S': 11612,
    'DILER YATIRIM BANKASI A.S.': 11613,
    'EFT MERKEZI': 11614,
    'EMKT MERKEZI': 11615,
    'FINANSBANK A.S.': 11616,
    'FIBABANKA A.S.': 11617,
    'GSD YATIRIM BANKASI A.S.': 11618,
    'HALK BANKASI': 11619,
    'HSBC BANK A.S.': 11620,
    'ICBC TURKEY BANK A.S': 11621,
    'ING BANK A.S.': 11622,
    'INTESA SANPAOLO S.p.A': 11623,
    'ILLER BANKASI': 11624,
    'ISTANBUL TAKAS VE SAKLAMA BANKASI': 11625,
    'JP MORGAN CHASE BANK NA MERK COL.OH': 11626,
    'KUVEYT TURK KATILIM BANKASI A.S.': 11627,
    'MERKEZI KAYIT KURULUSU': 11628,
    'MERRILL LYNCH YATIRIM BANK A.S.': 11629,
    'NUROL YATIRIM BANKASI A.S.': 11630,
    'ODEA BANK A.S.': 11631,
    'PASHA YATIRIM BANKASI A.S.': 11632,
    'RABOBANK A.S.': 11633,
    'SOCIETE GENERALE': 11634,
    'STANDARD CHARTERED YATIRIM BANKASI TURK A.S.': 11635,
    'SEKERBANK T.A.S.': 11636,
    'T.C.MERKEZ BANKASI A.S.': 11637,
    'T.EKONOMI BANKASI A.S.': 11638,
    'T.GARANTI BANKASI A.S.': 11639,
    'T.IHRACAT KREDI BANKASI A.S.': 11640,
    'T.IS BANKASI A.S.': 11641,
    'T.KALKINMA BANKASI A.S.': 11642,
    'T.SINAI KALK. BANKASI A.S.': 11643,
    'T.VAKIFLAR BANKASI T.A.O.': 11644,
    'TC ZIRAAT BANKASI': 11645,
    'THE ROYAL BANK OF SCOTLAND N.V. ISTANBUL': 11646,
    TURKISHBANK: 11647,
    'TURKIYE FINANS KATILIM BANKASI A.S.': 11648,
    'TURKLAND BANK A.S.': 11649,
    'VAKIF KATILIM BANKASI': 11650,
    'WESTDEUTSCHE LANDESBANK GIROZENTRAL': 11651,
    'YAPI VE KREDI BANKASI A.S.': 11652,
    YÖNLENDIRICI: 11653,
    'ZIRAAT KATILIM BANKASI A.S.': 11654
  },
  PE: {
    'Banco Central de Reserva': 11801,
    'Banco de Crédito del Perú': 11802,
    Interbank: 11803,
    Citibank: 11804,
    Scotiabank: 11805,
    'BBVA Continental': 11806,
    'Banco de la Nación': 11807,
    'Banco de Comercio': 11808,
    'Banco Financiero': 11809,
    'Banco Interamericano de Finanzas (BIF)': 11810,
    Santander: 11811,
    'Banco GNB Perú S.A.': 11812
  },
  AU: { 'All Banks / Payment System: AU': 11326 },
  GB: { 'All Banks / Payment System: GB': 11328 },
  IN: { 'All Banks / Payment System: IN': 11329 },
  US: { 'All Banks / Payment System: US': 11330 },
  SE: { 'All Banks / Payment System: SE': 11459 },
  ES: { 'All Banks / Payment System: ES': 11458 },
  PT: { 'All Banks / Payment System: PT': 11457 },
  PL: { 'All Banks / Payment System: PL': 11456 },
  NL: { 'All Banks / Payment System: NL': 11455 },
  MC: { 'All Banks / Payment System: MC': 11454 },
  MT: { 'All Banks / Payment System: MT': 11453 },
  LU: { 'All Banks / Payment System: LU': 11452 },
  LT: { 'All Banks / Payment System: LT': 11451 },
  LV: { 'All Banks / Payment System: LV': 11450 },
  IT: { 'All Banks / Payment System: IT': 11449 },
  IE: { 'All Banks / Payment System: IE': 11448 },
  DE: { 'All Banks / Payment System: DE': 11447 },
  FR: { 'All Banks / Payment System: FR': 11446 },
  FI: { 'All Banks / Payment System: FI': 11445 },
  EE: { 'All Banks / Payment System: EE': 11444 },
  DK: { 'All Banks / Payment System: DK': 11443 },
  CY: { 'All Banks / Payment System: CY': 11442 },
  BG: { 'All Banks / Payment System: BG': 11441 },
  BE: { 'All Banks / Payment System: BE': 11440 },
  AT: { 'All Banks / Payment System: AT': 11439 },
  CZ: { 'All Banks / Payment System: CZ': 13735 }
}
